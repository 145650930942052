body, .world-records-result-page-cont {
    overflow-x: hidden;
}

.world-records-result-page-cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

.wrrp-title-div {
    color: #cfcfcf;

    h2 {
        color: #cfcfcf;
        margin-bottom: 0rem;
        font-size: 1.6rem;
        margin-top: .1rem;
    }

    pre {
        color: #cfcfcf;
    }

    @media screen and (max-width: 800px) {
        h2 {
            font-size: 1.5rem;
            margin-bottom: -.3rem;
        }
        
    }
}

.wrrp-table-cont-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem;
    font-size: 1.0rem;
    // background-color: #8080804b;
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
    margin: 0rem 5rem;
    width: 40rem;

    
}

.wrrp-table-top-bar-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #76525270;
    width: 40rem;
    height: 2rem;
    color: #cfcfcf;
}

.wrrp-table-top3-cont {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 38rem;
    position: relative;


    h1{
        color: #cfcfcf;
        font-size: 1.6rem;
    }

    @media screen and (max-width: 800px) {
        width: 100%;
        margin-left: -3rem;
    }
}
// .wrrp-table-top3-cont::before {
//     content: '';
//     position: absolute;
//     top: -.8rem; /* Adjusts the glow's vertical position */
//     left: 0;
//     width: 100%;
//     height: 1rem; /* Adjusts the glow's height */
//     background: linear-gradient(to top, rgba(255, 217, 0, 0.047), transparent);
//     border-radius: 100% 100% 0 0; /* Creates a half-circle on top */
//     pointer-events: none; /* Ensures the glow doesn't interfere with user interactions */
// }

.wrrp-table-top3-pos-cont {
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-wrap: nowrap;
    color: #cfcfcf;
}

.wrrp-table-top3-1 {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    background: linear-gradient(to right, #0e2f61 20%, #0f346b );
    width: 33rem;
    height: 3rem;


    h1{
        color: #cfcfcf;
        font-size: 1.2rem;
        margin: 0rem 1rem;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        @media screen and (max-width: 800px) {
            font-size: .9rem;
            
        }
    }
    h2{
        color: #ebc000;
        font-size: 1.4rem;
        font-weight: bold;
        margin: 0rem 1rem;
        @media screen and (max-width: 800px) {
            margin-top: .2rem;
            font-size: 1.2rem;
        }
    }

    @media screen and (max-width: 800px) {
        width: 30rem;
        z-index: 1;
        flex-direction: column;
        
    }
}

.wrrp-table-top3-2 {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    background: linear-gradient(to right, #0e2f61ad 20%, #0f346bb3 );
    width: 30rem;
    height: 3rem;

    h1{
        color: #cfcfcf;
        font-size: 1.2rem;
        margin: 0rem 1rem;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        @media screen and (max-width: 800px) {
            font-size: .9rem;
            
        }
    }
    h2{
        color: #c2c2c2;
        font-size: 1.4rem;
        font-weight: bold;
        margin: 0rem 1rem;
        @media screen and (max-width: 800px) {
            margin-top: .2rem;
            font-size: 1.2rem;
        }
    }
    @media screen and (max-width: 800px) {
        width: 30rem;
        z-index: 1;
        flex-direction: column;
        
    }
}

.wrrp-table-top3-3 {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    background: linear-gradient(to right, #0e2f6164 20%, #0f346b66 );
    width: 27rem;
    height: 3rem;

    h1{
        color: #cfcfcf;
        font-size: 1.2rem;
        margin: 0rem 1rem;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        @media screen and (max-width: 800px) {
            font-size: .9rem;
            
        }
    }
    h2{
        color: rgb(179, 136, 98);
        font-size: 1.4rem;
        font-weight: bold;
        margin: 0rem 1rem;
        @media screen and (max-width: 800px) {
            margin-top: .2rem;
            font-size: 1.2rem;
        }
    }
    @media screen and (max-width: 800px) {
        width: 30rem;
        z-index: 1;
        flex-direction: column;
        
    }
}

.wrrp-table-field-row-cont {
    display: flex;
    flex-direction: column;
    justify-content: left;
    background: linear-gradient(to right, #383838 20%, #404040 );
    color: #cfcfcf;
    width: 38rem;
    margin-top: 0rem;
    padding: 0px;
    border-radius: .3rem;
    overflow: hidden;
    margin-bottom: 1rem;

    @media screen and (max-width: 800px) {
        width: 100%;
        
    }
}

.wrrptpos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-right: #828282 2px solid;
    background: linear-gradient(to right, #2b2b2b 20%, #343434 );
    width: 3rem;
}

.wrrp-table-row-f {
    display: flex;
    flex-direction: row;
    justify-content: left;
    border-bottom: #828282ab 1px solid;
    background: linear-gradient(to right, #2b2b2b 20%, #343434 );
    padding: none;

    .wrrptrd {
        color: #cfcfcf;
        font-size: 1.0rem;
        margin: 0rem 1rem 0rem 1.5rem;
        line-height: 2rem;

        @media screen and (max-width: 800px) {
            font-size: .8rem;
            
        }
    }

    .wrrptl {
        display: flex;
        flex-direction: row;
        justify-content: left;
        background: linear-gradient(to right, #2b2b2b 20%, #343434 );
        width: 26rem;
        height: 2rem;

        border-right: #cfcfcf34 1px solid;

        @media screen and (max-width: 800px) {
            height: auto;
            align-items: center;
            height: auto;
        }

        h1 {
            color: #cfcfcf;
            font-size: 1.1rem;
            margin: 0rem 1rem;
            padding-right: 1rem;
            line-height: 2rem;
            border-right: #cfcfcf34 1px solid;
        }

        h2 {
            color: #cfcfcf;
            font-size: 1.0rem;
            margin: 0rem 1rem;
            line-height: 2rem;

            &:hover {
                font-size: 1.03rem;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}

.wrrp-table-top-3-flex {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 38rem;

    .wrrp-table-top3-pos1 {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        background: linear-gradient(to right, #2b2b2b 20%, #343434 );
        width: auto;
        padding: 1rem 1rem;
        height: 3rem;
        font-size: 1.2rem;
        font-weight: bold;
        color: #cfcfcf;

        @media screen and (max-width: 800px) {
            display: none;
            
        }
    }

    .wrrp-table-top3-pos2 {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        background: linear-gradient(to right, #2b2b2b 20%, #343434 );
        width: auto;
        height: 3rem;
        padding: 1rem 1rem;
        font-size: 1.2rem;
        font-weight: bold;
        color: #cfcfcf;

        @media screen and (max-width: 800px) {
            display: none;
            
        }
    }

    .wrrp-table-top3-pos3 {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        background: linear-gradient(to right, #2b2b2b 20%, #343434 );
        width: auto;
        padding: 1rem 1rem;
        height: 3rem;
        font-size: 1.2rem;
        font-weight: bold;
        color: #cfcfcf;

        @media screen and (max-width: 800px) {
            display: none;
            
        }
    }

    @media screen and (max-width: 800px) {
        width: auto;
        overflow: hidden;
}
}
.wrrp-table-top3-right-cont{
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 8rem;
    text-wrap: nowrap;
    color: #cfcfcf;

    .wrrp-table-top3-right-1{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1rem;
        height: 3rem;

        @media screen and (max-width: 800px) {
            margin-left: 0rem;
            
        }
    }
    .wrrp-table-top3-right-2{
        display: flex;
        align-items: center;
        justify-content: left;
        margin-left: -2rem;
        height: 3rem;

        @media screen and (max-width: 800px) {
            margin-left: 0rem;
            
        }
    }
    .wrrp-table-top3-right-3
    {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -9rem;
        height: 3rem;

        @media screen and (max-width: 800px) {
            margin-left: 0rem;
            
        }
    }

    @media screen and (max-width: 800px) {
        width: auto;
        font-size: 0.7rem;
        margin-left: -5rem;
        z-index: 2;
    }
    
}

}

.no-wr-found-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;

    .no-wr-found-div {
        color: #cfcfcf;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        border-radius: 1rem;
        background-color: #8080804b;
        padding: 1rem 1rem;
    }
    
    .go-to-search-wr-button1 {
        color: #cfcfcf;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        background: linear-gradient(to right, #0e2f61 20%, #0f346b );
        width: 20rem;
        height: 3rem;
        border: 2px solid #cfcfcf18;
        border-radius: 1rem;

        &:hover {
            background: linear-gradient(to right, #10366f 20%, #113976 );
            cursor: pointer;
        }
    }
}