.season-summary-graph-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.ssm-title-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: rgb(200,200,200);
    font-size: 1.5rem;
    font-weight: lighter;
    margin-bottom: 1rem;
    margin-top: 1rem;
}