.prev-button-sp {
  background-color: #154fa7;
  color: #dddddd;
  border-radius: 0.2rem;
  margin-left: 10rem;
  border: 1px solid #adadad;
}
@media screen and (max-width: 800px) {
  .prev-button-sp {
    margin-left: 2rem;
  }
}
.prev-button-sp:hover {
  color: rgb(241, 196, 15);
  background-color: #24487c;
}

.sp-page-background {
  --s: 1550px; /* control the size */
  background: linear-gradient(135deg, rgba(0, 0, 0, 0) 18.75%, #252427 0 31.25%, rgba(0, 0, 0, 0) 0), repeating-linear-gradient(45deg, #252427 -6.25% 6.25%, #262528 0 18.75%);
  background-size: var(--s) var(--s);
}

.data-table-container-sp {
  margin: 20px 0;
  overflow-x: auto; /* Use this to make the table scrollable horizontally on smaller screens */
}
@media screen and (min-width: 800px) {
  .data-table-container-sp {
    margin-left: 10rem;
    margin-right: 10rem;
  }
}

.data-table-sp {
  width: 100%;
  border-collapse: collapse;
}

.data-table-sp th {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  border-bottom: 1px solid #f1c40f;
  background: linear-gradient(to right, #29272b 20%, #302e31);
  text-align: left;
  padding: 8px;
  border-top: #cccccc solid 1px;
  border-left: #cccccc solid 1px;
  border-right: #cccccc solid 1px;
  color: #cccccc;
}

.data-table-sp td {
  border: 1px solid #cfcfcf;
  padding: 8px;
  color: #cfcfcf;
  height: 2rem;
  max-height: 2rem;
  line-height: 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-left: 1rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
@media screen and (max-width: 800px) {
  .data-table-sp td {
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8rem;
  }
}

.next-button-sp {
  background-color: #154fa7;
  color: #dddddd;
  border-radius: 0.2rem;
  border: none;
  margin-right: 10rem;
  border: 1px solid #adadad;
}
@media screen and (max-width: 800px) {
  .next-button-sp {
    margin-right: 2rem;
  }
}
.next-button-sp:hover {
  color: rgb(241, 196, 15);
  background-color: #24487c;
}

.min-sof-sp {
  width: 4rem;
  padding-left: 10px;
  font-size: small;
  background-color: #29272b;
  border: none;
  border-radius: 0.1rem;
  color: #dddddd;
}
@media screen and (max-width: 800px) {
  .min-sof-sp {
    min-height: 1rem;
    line-height: 1rem;
  }
}

.max-sof-sp {
  width: 4rem;
  padding-left: 10px;
  font-size: small;
  background-color: #29272b;
  border: none;
  border-radius: 0.1rem;
  color: #dddddd;
}
@media screen and (max-width: 800px) {
  .max-sof-sp {
    min-height: 1rem;
    line-height: 1rem;
  }
}

.min-container-sp {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #dddddd;
  width: 10rem;
  min-height: 3rem;
  margin-bottom: 0;
}

.max-container-sp {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #dddddd;
  width: 10rem;
}

.sof-color-background {
  background: linear-gradient(to right, #3d3d3d 20%, #313131);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0.2rem 0.8rem 0.2rem 0.8rem;
  border-radius: 0.1rem;
  gap: 0.5rem;
  margin-bottom: 0rem;
  min-height: 1.7rem;
}
.td-pointer {
  cursor: pointer;
  line-height: 2rem;
  text-align: left;
}
@media screen and (max-width: 800px) {
  .td-pointer {
    font-size: 0.8rem;
  }
}

.container-sof-changer {
  display: flex;
  flex-direction: row;
  color: #dddddd;
  justify-content: center;
  align-items: center;
  margin-bottom: 0rem;
  margin-top: 0rem;
}
.hour-of-day-selector {
  display: flex;
  flex-direction: row;
  color: #dddddd;
  justify-content: center;
  align-items: center;
  margin-bottom: 0rem;
  margin-top: 0rem;
}
.hour-of-day-selector-text {
  font-size: small;
  margin-right: 1rem;
  margin-left: 1rem;
}
@media screen and (max-width: 800px) {
  .hour-of-day-selector-text {
    font-size: small;
  }
}

.min-hour-selector {
  width: 4rem;
  padding-left: 10px;
  font-size: small;
  background-color: #29272b;
  border: none;
  border-radius: 0.1rem;
  color: #dddddd;
}
@media screen and (max-width: 800px) {
  .min-hour-selector {
    min-height: 1rem;
    line-height: 1rem;
  }
}

.max-hour-selector {
  width: 4rem;
  padding-left: 10px;
  font-size: small;
  background-color: #29272b;
  border: none;
  border-radius: 0.1rem;
  color: #dddddd;
}
@media screen and (max-width: 800px) {
  .max-hour-selector {
    min-height: 1rem;
    line-height: 1rem;
  }
}

.filters-banner-sof {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 0.2rem;
  gap: 0.2rem;
}
@media screen and (max-width: 800px) {
  .filters-banner-sof {
    flex-direction: column;
    align-items: center;
  }
}

.current-utc-time-container {
  display: flex;
  flex-direction: row;
  color: #dddddd;
  justify-content: center;
  align-items: center;
  margin-bottom: 0rem;
  margin-top: 0rem;
}
.wet-toggle-container {
  display: flex;
  flex-direction: row;
  color: #dddddd;
  justify-content: center;
  align-items: center;
  margin-left: 0.2rem;
  margin-bottom: 0rem;
  margin-top: 0rem;
}
.wet-toggle-container .switch {
  padding: 0px;
}
.wet-toggle-container .checkbox {
  align-self: center;
}

.sof-sp-label {
  font-size: x-small;
  margin: 0;
}

.min-sof-sp-label {
  font-size: small;
  border-bottom: #dddddd solid 1px;
  margin: 0;
}

.max-sof-sp-label {
  font-size: small;
  border-bottom: #dddddd solid 1px;
  margin: 0;
}

.chart-nav-buttons-sp-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.6rem;
  margin-top: 0rem;
}

.chart-cat-buttons-sp-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0rem;
  margin-top: 0rem;
  padding-left: 192px;
  padding-right: 192px;
}
@media screen and (max-width: 800px) {
  .chart-cat-buttons-sp-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.loading-text-div-sp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-size: large;
  color: #dddddd;
}

.chart-cat-buttons-sp-div,
.chart-cat-buttons-sp-div-le,
.chart-cat-buttons-sp-div-re {
  display: inline-block;
  padding: 0.3rem 1.4rem 0.3rem 1.4rem;
  background: linear-gradient(to right, #154fa7 20%, #12428a);
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0rem;
  margin-bottom: 1rem;
  margin-top: -0.1rem;
  color: white;
  font-size: 0.9rem;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  border: none;
  cursor: pointer;
}
.chart-cat-buttons-sp-div:hover,
.chart-cat-buttons-sp-div-le:hover,
.chart-cat-buttons-sp-div-re:hover {
  transform: scale(1.05);
  transition: all 0.1s ease-in-out;
  color: #f1c40f;
}
.chart-cat-buttons-sp-div:active,
.chart-cat-buttons-sp-div-le:active,
.chart-cat-buttons-sp-div-re:active {
  transform: scale(0.95);
  transition: all 0.1s ease-in-out;
}
@media screen and (max-width: 800px) {
  .chart-cat-buttons-sp-div,
  .chart-cat-buttons-sp-div-le,
  .chart-cat-buttons-sp-div-re {
    border-radius: 0px;
    width: 3.7rem;
    height: 3rem;
    font-size: 0.7rem;
    padding: 0.2rem 1rem 0.2rem 1rem;
  }
  .chart-cat-buttons-sp-div h1,
  .chart-cat-buttons-sp-div-le h1,
  .chart-cat-buttons-sp-div-re h1 {
    font-size: 1.4rem;
  }
}

.chart-cat-buttons-sp-div-le {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.chart-cat-buttons-sp-div-re {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.sp-disclaimer-text-div {
  font-size: small;
  color: #a5a5a5;
}
@media screen and (max-width: 800px) {
  .sp-disclaimer-text-div {
    font-size: x-small;
  }
}

.top-background-div-spop {
  background: linear-gradient(-45deg, #5e3169, #2f3585, #1d427a, #5e3169);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 10rem;
  width: 48rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 0.6rem;
  color: #dddddd;
  margin-top: 5rem;
}
.top-background-div-spop h1 {
  font-size: x-large;
}
@media screen and (max-width: 800px) {
  .top-background-div-spop {
    border-radius: 0px;
    width: 100%;
    height: 14rem;
    margin-top: 0rem;
  }
  .top-background-div-spop h1 {
    font-size: 1.4rem;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.top-background-div-spop-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.plot-icon-container {
  display: flex;
  flex-direction: row;
}

.plot-icon-column-div {
  background-color: #5e3169;
  min-height: 10rem;
  max-width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.plot-icon {
  width: 2rem;
}/*# sourceMappingURL=SeriesPopularity.css.map */