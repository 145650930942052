.wr-page-main {
  color: #cfcfcf;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wr-page-main .wr-selection-square-container-div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0rem;
  margin-bottom: 1rem;
}
.wr-page-main .wr-selection-square-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(96, 95, 95, 0.2941176471);
  border-radius: 0.5rem;
  margin: 0.1rem;
  padding: 0.2rem 0.3rem;
  font-size: 0.7rem;
}
.wr-page-main .wr-selection-square-div:hover {
  cursor: pointer;
  background-color: #545454;
}
@media screen and (max-width: 800px) {
  .wr-page-main .wr-selection-square-div {
    padding: 0.2rem 0.5rem;
    font-size: 0.7rem;
  }
}
.wr-page-main .wr-car-selection-big-containing-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  font-size: 1rem;
  background-color: rgba(128, 128, 128, 0.1490196078);
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-bottom: rgba(207, 207, 207, 0.0862745098) 1px solid;
  margin: 0rem 5rem;
  width: 40rem;
  height: 10rem;
}
@media screen and (max-width: 800px) {
  .wr-page-main .wr-car-selection-big-containing-div {
    width: 100%;
  }
}
.wr-page-main .wr-track-selection-big-containing-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  font-size: 1rem;
  background-color: rgba(128, 128, 128, 0.1490196078);
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  margin: 0rem 5rem;
  width: 40rem;
  height: 10rem;
}
@media screen and (max-width: 800px) {
  .wr-page-main .wr-track-selection-big-containing-div {
    width: 100%;
  }
}
.wr-page-main .wr-search-bar {
  background-color: rgba(128, 128, 128, 0);
  border-bottom: 1px solid #cfcfcf;
  width: 20rem;
  border-right: none;
  overflow: hidden;
  color: #cfcfcf;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-top: none;
  border-left: none;
}
.wr-page-main .wr-selection-go-button {
  background: linear-gradient(to right, #154fa7 20%, #12428a);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #cfcfcf;
  width: 10rem;
}
.wr-page-main .wr-selection-go-button:hover {
  cursor: pointer;
  background-color: #545454;
}/*# sourceMappingURL=WorldRecordsPage.css.map */