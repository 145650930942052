.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #29272b;
  margin-top: 5rem;
}

h2 {
  margin-bottom: 20px;
  color: #cfcfcf;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px;
  border-radius: 5px;
  background: linear-gradient(to right, #3d3d3d 20%, #313131);
  background-size: 400% 400%;
  animation: gradient 30s ease infinite;
}

.login-form input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-form button {
  padding: 10px;
  background-color: #f1c40f;
  color: #2c2c2c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.forgot_pword {
  color: #cfcfcf;
  font-size: small;
}
.forgot_pword:hover {
  color: #f1c40f;
  cursor: pointer;
}

.regist-buttn {
  background-color: #f1c40f;
  color: #2e2e2e;
  border: none;
  border-radius: 0.2rem;
  cursor: pointer;
}
.regist-buttn:hover {
  background-color: #dfc24f;
}

.login-form button:hover {
  background-color: #0056b3;
}

.register-link {
  margin-top: 4rem;
  text-align: center;
  color: #cfcfcf;
  cursor: pointer;
}/*# sourceMappingURL=LoginPage.css.map */