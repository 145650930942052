@import "../variables/Variables.scss";

.fp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $backgroundGrurple;
    margin-top: 5rem;
    color: #cfcfcf;
}

h2 {
    margin-bottom: 20px;
    color: #cfcfcf;
}

.fp-input-div-top {
    align-self: center;
    border-radius: .2rem;
    border: none;
}

.enter-email-div-fp {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border-radius: 5px;
    background: linear-gradient(-45deg, #733583, #154fa7, #154fa7, #733583);
    background-size: 400% 400%;
    animation: gradient 30s ease infinite;
}

.fp-lower-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border-radius: 5px;
    background: linear-gradient(-45deg, #733583, #154fa7, #154fa7, #733583);
    background-size: 400% 400%;
    animation: gradient 30s ease infinite;
}

.fp-input-div,
.fp-lower-container label {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    border-radius: .2rem;
    border: none;
}

.fp-input-div input,
.fp-lower-container select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.fp-submit-button {
    padding: 10px;
    background-color: #f1c40f;
    color: #2c2c2c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.fp-top-submit {
    background-color: #f1c40f;
    color: #2c2c2c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 6rem;
    align-self: center;
}

.fp-submit-button:hover {
    background-color: #0056b3;
}

p {
    margin-top: 10px;
    color: #cfcfcf;
}
