.homepage-banner {
  align-items: center;
  justify-content: center;
  background-color: #f1c40f;
  color: #29272b;
  margin-bottom: 0rem;
  width: auto;
}
.homepage-banner h1 {
  font-size: larger;
  font-weight: normal;
}

/* Animation */
@keyframes slideText {
  from {
    transform: translateX(50%); /* Start off-screen to the right */
  }
  to {
    transform: translateX(-90%); /* End off-screen to the left */
  }
}
.patreon-subscriber-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(41, 39, 43, 0);
  color: #cfcfcf;
  margin-bottom: 0rem;
  margin-top: 0rem;
  height: 2rem;
  line-height: 2rem;
  padding-top: 0rem;
  width: 12rem; /* Adjust based on your layout */
  overflow: hidden;
  white-space: nowrap; /* Ensure the text is in a single line */
}
.patreon-subscriber-carousel h1 {
  font-size: larger;
  font-weight: lighter;
}
.patreon-subscriber-carousel .patreon-subscriber-carousel-text {
  font-size: 0.6rem;
  display: inline-block; /* Treat the div as an inline element */
  padding-left: 80%; /* Optional: Ensure there's a delay before text starts sliding in */
  animation: slideText 30s linear infinite; /* Adjust timing as needed */
  margin-top: 2rem;
}
@media screen and (max-width: 800px) {
  .patreon-subscriber-carousel .patreon-subscriber-carousel-text {
    font-size: x-small;
    margin-top: 1.4rem;
  }
}

.patreon-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
  gap: 0rem;
}
@media screen and (max-width: 800px) {
  .patreon-banner {
    margin-top: 1rem;
    gap: 0.6rem;
  }
}
.patreon-banner .patreon-banner-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 800px) {
  .patreon-banner .patreon-banner-box {
    flex-direction: column;
    gap: 0.2rem;
  }
}
.patreon-banner .patreon-banner-text-l {
  color: #cfcfcf;
  margin-right: 0rem;
}
@media screen and (max-width: 800px) {
  .patreon-banner .patreon-banner-text-l {
    font-size: x-small;
    margin-bottom: 0rem;
  }
}
.patreon-banner .whysupport {
  font-size: x-small;
  margin-left: -0.1rem;
}

.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  --s: 1550px; /* control the size */
  background: linear-gradient(135deg, rgba(0, 0, 0, 0) 18.75%, #252427 0 31.25%, rgba(0, 0, 0, 0) 0), repeating-linear-gradient(45deg, #252427 -6.25% 6.25%, #262528 0 18.75%);
  background-size: var(--s) var(--s);
}
.homepage .homepage-recent-session-cont {
  margin-top: 0rem;
  margin-bottom: 2rem;
  color: #cfcfcf;
  background: linear-gradient(to right, #2f2f2f 20%, #2e2e2e);
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  height: auto;
  width: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  border-bottom: #3d3d3d 1px solid;
  border-left: #3d3d3d 1px solid;
  border-right: #3d3d3d 1px solid;
}
@media screen and (max-width: 800px) {
  .homepage .homepage-recent-session-cont {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
    width: 18rem;
  }
}
.homepage .homepage-recent-session-cont .sof-and-time-cont {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: start;
  gap: 0rem;
}
.homepage .homepage-recent-session-cont .sof-and-time-left {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: end;
  border-right: #cfcfcf 1px solid;
  gap: 0.15rem;
  padding-right: 0.5rem;
}
.homepage .homepage-recent-session-cont .sof-and-time-right {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;
  gap: 0.15rem;
  margin-left: 0.5rem;
}
.homepage .homepage-recent-session-cont .recent-session-time {
  font-size: xx-small;
}
.homepage .homepage-recent-session-cont .recent-session-sof {
  font-size: xx-small;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.homepage .homepage-recent-session-cont .recent-session-tile {
  background: linear-gradient(to right, #154fa7 20%, #12428a);
  box-shadow: 2px 2px 2px 0px #2a2a2c;
  border: 1px solid rgba(79, 101, 143, 0.8901960784);
  border-radius: 0.15rem;
  width: 100%;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
}
@media screen and (max-width: 800px) {
  .homepage .homepage-recent-session-cont .recent-session-tile {
    min-width: 40%;
  }
}
.homepage .homepage-recent-session-cont .recent-session-tile:hover {
  cursor: pointer;
  background: linear-gradient(to right, #154fa7 20%, #12428a);
  color: #f1c40f;
  transform: scale(1.05);
  transition: all 0.1s ease-in-out;
}
.homepage .homepage-recent-session-cont .recent-session-season-name {
  font-size: small;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: #cfcfcf 1px solid;
}
.homepage .cust_id_input_div {
  color: #cfcfcf;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 0rem;
  background: linear-gradient(to right, #2f2f2f 20%, #2e2e2e);
  height: 9.5rem;
  width: 29rem;
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  padding-top: 2rem;
  border-bottom: #3d3d3d 1px solid;
  border-left: #3d3d3d 1px solid;
  border-right: #3d3d3d 1px solid;
}
.homepage .cust_id_input_div h3 {
  font-size: small;
  margin-bottom: 0rem;
  border-bottom: #f1c40f 1px solid;
  width: 9rem;
  align-self: center;
  margin-bottom: 0rem;
}
.homepage .cust_id_input_div p {
  font-size: x-small;
  cursor: pointer;
  margin-top: 0rem;
  margin-left: -1rem;
  margin-bottom: 1.2rem;
}
.homepage .cust_id_input_div p:hover {
  color: #f1c40f;
}
.homepage h1 {
  font-size: 1.8rem;
  color: #dddddd;
  margin-bottom: 0rem;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  font-weight: lighter;
}
.homepage .homepage-square-organizing-cont-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  overflow: auto;
  margin-bottom: 1rem;
}
@media screen and (max-width: 800px) {
  .homepage .homepage-square-organizing-cont-top {
    gap: 1rem;
    height: 28rem;
    margin-bottom: 0rem;
  }
}
.homepage .homepage-square-organizing-cont-top .patreon-square {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
}
.homepage .homepage-square-organizing-cont-top .patreon-square .patreon_logo_home {
  width: 8rem;
  margin-top: 1.1rem;
  margin-bottom: 1rem;
}
@media screen and (max-width: 800px) {
  .homepage .homepage-square-organizing-cont-top .patreon-square .patreon_logo_home {
    width: 6rem;
    margin-bottom: 0rem;
    margin-top: 0rem;
  }
}
.homepage .homepage-square-organizing-cont-top .homepage-patreon-sq {
  background: linear-gradient(to right, #2f2f2f 20%, #2e2e2e);
  width: 14rem;
  padding: 0.5rem;
  padding-top: 1rem;
  border-radius: 0.2rem;
  height: 11.3rem;
  border: 1px solid #3d3d3d;
}
@media screen and (max-width: 800px) {
  .homepage .homepage-square-organizing-cont-top .homepage-patreon-sq {
    width: 100%;
    padding: 1.6rem;
    padding-top: 1.2rem;
    gap: 0rem;
    height: 7rem;
  }
}
.homepage .home-charts-title {
  font-size: 1rem;
  color: #cfcfcf;
  margin-top: 0rem;
  padding-top: 0.1rem;
  margin-bottom: 0rem;
  background: linear-gradient(to right, #3e3f40 30%, #39393a);
  margin-bottom: 0rem;
  border-top-right-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
  width: 14rem;
  height: 1.8rem;
  line-height: 1.6rem;
  border-top: #3d3d3d 1px solid;
  border-left: #3d3d3d 1px solid;
  border-right: #3d3d3d 1px solid;
}
@media screen and (max-width: 800px) {
  .homepage .home-charts-title {
    width: 18rem;
    margin-top: 1rem;
  }
}
.homepage .home-top-title {
  font-size: 1rem;
  color: #cfcfcf;
  margin-top: 0rem;
  padding-top: 0.1rem;
  margin-bottom: 0rem;
  background: linear-gradient(to right, #3e3f40 30%, #39393a);
  margin-bottom: 0rem;
  border-top-right-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
  width: 29rem;
  height: 1.8rem;
  line-height: 1.6rem;
  border-top: #3d3d3d 1px solid;
  border-left: #3d3d3d 1px solid;
  border-right: #3d3d3d 1px solid;
}
@media screen and (max-width: 800px) {
  .homepage .home-top-title {
    width: 29rem;
    margin-top: 1rem;
  }
}
.homepage .home-top-title1 {
  font-size: 1rem;
  color: #cfcfcf;
  margin-top: 0rem;
  padding-top: 0.1rem;
  margin-bottom: 0rem;
  background: linear-gradient(to right, #3e3f40 30%, #39393a);
  margin-bottom: 0rem;
  border-top-right-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
  width: 14rem;
  height: 1.8rem;
  line-height: 1.6rem;
}
@media screen and (max-width: 800px) {
  .homepage .home-top-title1 {
    width: 14rem;
    margin-top: 1rem;
  }
}
.homepage .home-charts-title-rs {
  font-size: 1rem;
  color: #cfcfcf;
  margin-bottom: 0rem;
  padding-top: 0.1rem;
  background: linear-gradient(to right, #3e3f40 30%, #39393a);
  margin-bottom: 0rem;
  border-top-right-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
  width: 14rem;
  height: 1.8rem;
  line-height: 1.6rem;
  border-top: #3d3d3d 1px solid;
  border-left: #3d3d3d 1px solid;
  border-right: #3d3d3d 1px solid;
}
@media screen and (max-width: 800px) {
  .homepage .home-charts-title-rs {
    width: 18rem;
    margin-top: 1rem;
  }
}
.homepage .input-container-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}
.homepage .input-container-row input {
  width: 16rem;
  font-size: small;
  margin-left: 0rem;
}
@media screen and (max-width: 800px) {
  .homepage .input-container-row {
    margin-top: 0rem;
  }
}
.homepage .site_logo_img {
  height: 4.2rem;
  width: 18rem;
  margin-top: -0.6rem;
  margin-bottom: 1rem;
}
@media screen and (max-width: 800px) {
  .homepage .site_logo_img {
    height: 4.2rem;
    width: 18rem;
  }
}
.homepage .cs-examples-container {
  display: flex;
  flex-direction: column;
  font-size: x-small;
  justify-content: center;
  align-items: center;
  margin-top: 0rem;
}
.homepage .cs-examples-container h2 {
  font-size: small;
  margin-bottom: 0px;
  color: #f1c40f;
}
@media screen and (max-width: 800px) {
  .homepage .cs-examples-container {
    margin-top: 0rem;
  }
}
.homepage .button-go {
  color: #f1c40f;
  border: #f1c40f 1px solid;
  font-weight: bold;
  border-radius: 0.4rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 1px;
  cursor: pointer;
}
.homepage .button-go:hover {
  cursor: pointer;
  color: #f1c40f;
  transform: scale(1.05);
  transition: all 0.1s ease-in-out;
}
.homepage .home-charts-title-2 {
  font-size: 1rem;
  color: #cfcfcf;
  width: 14rem;
  padding-top: 0.1rem;
  background: linear-gradient(to right, #3e3f40 30%, #39393a);
  margin-bottom: 0rem;
  border-top-right-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
  height: 1.8rem;
  line-height: 1.6rem;
  border-top: #3d3d3d 1px solid;
  border-left: #3d3d3d 1px solid;
  border-right: #3d3d3d 1px solid;
}
@media screen and (max-width: 800px) {
  .homepage .home-charts-title-2 {
    width: 18rem;
    margin-top: 1rem;
  }
}
.homepage .home-charts-title-mc {
  font-size: 1rem;
  color: #cfcfcf;
  width: 44rem;
  background: linear-gradient(to right, #3e3f40 30%, #39393a);
  margin-bottom: 0rem;
  padding-top: 0.1rem;
  border-top-right-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
  height: 1.8rem;
  line-height: 1.6rem;
  border-top: #3d3d3d 1px solid;
  border-left: #3d3d3d 1px solid;
  border-right: #3d3d3d 1px solid;
}
@media screen and (max-width: 800px) {
  .homepage .home-charts-title-mc {
    width: 18rem;
    margin-top: 1rem;
  }
}
.homepage .top-background-div {
  background-size: 400% 400%;
  animation: gradient 30s ease infinite;
  height: 18rem;
  width: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.2rem;
  margin-bottom: 0rem;
  margin-top: 0rem;
}
.homepage .top-background-div h1 {
  margin-top: 4rem;
  border-bottom: #f1c40f 1px solid;
  width: 20rem;
}
@media screen and (max-width: 800px) {
  .homepage .top-background-div {
    border-radius: 0px;
    width: 100%;
    height: 16rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .homepage .top-background-div h1 {
    font-size: 1.4rem;
  }
}
@keyframes gradient {
  0%, 30% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  70%, 100% {
    background-position: 0% 50%;
  }
}
.homepage .example_users_text {
  height: 1.1rem;
  width: 6rem;
  margin-top: 0.2rem;
}
.homepage .example_users_text i {
  font-size: 0.6rem;
  margin-left: 0rem;
}
@media screen and (max-width: 800px) {
  .homepage .example_users_text {
    margin-top: 0rem;
    width: 5rem;
  }
}
.homepage .example_users_text:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: all 0.1s ease-in-out;
}
.homepage .example_users_text:hover i {
  color: #f1c40f;
}
.homepage .example_users_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 0rem;
  width: 22rem;
  margin-left: 0rem;
  border: rgba(61, 61, 61, 0.3333333333) 3px solid;
  background-color: rgba(61, 61, 61, 0.3333333333);
  min-height: 2rem;
  padding-bottom: 1rem;
  padding-top: 0rem;
  padding-left: 1.2rem;
  border-radius: 0.7rem;
}
@media screen and (max-width: 800px) {
  .homepage .example_users_container {
    padding-top: 0.4rem;
  }
}
.homepage .top-separator-div {
  height: 0.3rem;
  background-color: rgba(129, 138, 150, 0.062745098);
  width: 82%;
  border-radius: 1rem;
  margin-top: 2rem;
}
.homepage .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.575);
  display: flex;
  justify-content: center;
  align-items: center;
}
.homepage .modal-content {
  background: rgb(48, 47, 49);
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
}
.homepage .modal-text {
  background: rgb(48, 47, 49);
  color: #cfcfcf;
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-top: 0rem;
}
.homepage .ui_id_img {
  margin-bottom: 1rem;
}
.homepage .modal_close_button {
  background-color: #f1c40f;
  color: #29272b;
  width: 6rem;
  height: 2rem;
  align-self: center;
  border: none;
  border-radius: 0.25rem;
  margin-top: 2rem;
}
.homepage .modal_close_button:hover {
  cursor: pointer;
  background-color: #f1c40f;
  color: #29272b;
  transform: scale(1.05);
  transition: all 0.1s ease-in-out;
}
.homepage .button-container-mc-2 {
  margin-top: 0rem;
  margin-bottom: 2rem;
  color: #cfcfcf;
  background: linear-gradient(to right, #2f2f2f 20%, #2e2e2e);
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  height: auto;
  width: 44rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  border-bottom: #3d3d3d 1px solid;
  border-left: #3d3d3d 1px solid;
  border-right: #3d3d3d 1px solid;
}
@media screen and (max-width: 800px) {
  .homepage .button-container-mc-2 {
    flex-direction: row;
    width: 18rem;
    height: auto;
    margin-bottom: 0rem;
  }
}
.homepage .button-container {
  margin-top: 0rem;
  margin-bottom: 2rem;
  color: #cfcfcf;
  background: linear-gradient(to right, #2f2f2f 20%, #2e2e2e);
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  height: auto;
  width: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  border-bottom: #3d3d3d 1px solid;
  border-left: #3d3d3d 1px solid;
  border-right: #3d3d3d 1px solid;
}
@media screen and (max-width: 800px) {
  .homepage .button-container {
    width: 100%;
    height: auto;
    margin-bottom: 0rem;
  }
}
.homepage .button-container .homepage-button {
  display: inline-block;
  padding: 1rem 1.2rem;
  background: linear-gradient(to right, #154fa7 20%, #12428a);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  color: #dddddd;
  font-size: 0.9rem;
  border-radius: 0.15rem;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 2px 2px 2px 0px #2a2a2c;
  border: 1px solid rgba(79, 101, 143, 0.8901960784);
}
.homepage .button-container .homepage-button:hover {
  transform: scale(1.05);
  transition: all 0.1s ease-in-out;
  color: #f1c40f;
}
.homepage .button-container .homepage-button:active {
  transform: scale(0.95);
  transition: all 0.1s ease-in-out;
}
@media only screen and (max-width: 800px) {
  .homepage .button-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 18rem;
  }
}

.homepage-square-organizing-cont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  overflow: auto;
  margin-bottom: -1rem;
}
@media screen and (max-width: 800px) {
  .homepage-square-organizing-cont {
    gap: 1rem;
    margin-bottom: 0rem;
  }
}

.homepage-square-organizing-cont-sponsor {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: auto;
  margin-bottom: 1rem;
}
@media screen and (max-width: 800px) {
  .homepage-square-organizing-cont-sponsor {
    gap: 1rem;
    margin-bottom: 0rem;
  }
}
.homepage-square-organizing-cont-sponsor .homepage-square-organizing-sponsor {
  width: 44rem;
  height: 2.5rem;
  border: #3d3d3d 1px solid;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #cfcfcf;
  font-size: large;
}

.notification-bubble-sp {
  background-color: #3d424a;
  position: absolute;
  height: 1.4rem;
  top: 0px; /* Adjust these values to position the bubble correctly */
  left: 0px; /* Adjust these values to position the bubble correctly */
  color: white;
  border-radius: 0%;
  padding: 0rem 0.4rem; /* Adjust the padding to make the bubble circular */
  font-size: 12px; /* Adjust the font size */
  text-shadow: none;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: auto;
  justify-content: center;
}

.homepage-square-organizing-cont-bottom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  overflow: auto;
  margin-bottom: 1rem;
}
@media screen and (max-width: 800px) {
  .homepage-square-organizing-cont-bottom {
    gap: 1rem;
    margin-bottom: 1rem;
  }
}

.homepage-square-organizing-cont-wr {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0rem;
  justify-content: center;
  overflow: auto;
  margin-bottom: -1rem;
}
@media screen and (max-width: 800px) {
  .homepage-square-organizing-cont-wr {
    gap: 0rem;
    margin-bottom: 0rem;
  }
}
.homepage-square-organizing-cont-wr .homepage-square-organizing-wr {
  margin-top: 0rem;
  margin-bottom: 2rem;
  color: #cfcfcf;
  background: linear-gradient(to right, #2f2f2f 20%, #2e2e2e);
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  height: auto;
  width: 44rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  border-bottom: #3d3d3d 1px solid;
  border-left: #3d3d3d 1px solid;
  border-right: #3d3d3d 1px solid;
}
@media screen and (max-width: 800px) {
  .homepage-square-organizing-cont-wr .homepage-square-organizing-wr {
    flex-direction: column;
    width: 18rem;
    height: auto;
    margin-bottom: 1rem;
  }
}
.homepage-square-organizing-cont-wr .homepage-square-organizing-wr-title {
  font-size: 1rem;
  color: #cfcfcf;
  margin-top: 0rem;
  padding-top: 0.1rem;
  margin-bottom: 0rem;
  background: linear-gradient(to right, #3e3f40 30%, #39393a);
  margin-bottom: 0rem;
  border-top-right-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
  width: 44rem;
  height: 1.8rem;
  line-height: 1.6rem;
  border-top: #3d3d3d 1px solid;
  border-left: #3d3d3d 1px solid;
  border-right: #3d3d3d 1px solid;
}
@media screen and (max-width: 800px) {
  .homepage-square-organizing-cont-wr .homepage-square-organizing-wr-title {
    width: 18rem;
    margin-top: 1rem;
  }
}

.homepage-wr-recent-title {
  font-size: 0.7rem;
  color: #cfcfcf;
  width: 2rem;
  margin: 0rem 1rem 0rem 0.4rem;
}
@media screen and (max-width: 800px) {
  .homepage-wr-recent-title {
    width: auto;
  }
}

.homepage-wr-recent-search-square {
  cursor: pointer;
  background: linear-gradient(to right, #154fa7 20%, #12428a);
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  /* Replace incorrect property */
  white-space: nowrap;
  /* Ensure the parent doesn't interfere */
  overflow: hidden;
  width: 9rem;
  height: 3rem;
  /* Other properties */
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 2px 2px 2px 0px #2a2a2c;
  border: 1px solid rgba(79, 101, 143, 0.8901960784);
  padding: 0rem 0.4rem;
  justify-content: center;
  color: #cfcfcf;
}
.homepage-wr-recent-search-square:hover {
  background: linear-gradient(to right, #124da7 20%, #0d3d85);
  transition: all 0.1s ease-in-out;
  color: #f1c40f;
}
@media screen and (max-width: 800px) {
  .homepage-wr-recent-search-square {
    width: 16rem;
  }
}

.homepage-wr-see-more {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0) !important;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  width: 6.5rem;
  height: 3rem;
  font-size: 0.7rem;
  /* Other properties */
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 2px 2px 2px 0px #2a2a2c;
  border: 1px solid #f1c40f;
  padding: 0rem 0.4rem;
  justify-content: center;
  border-radius: 0.3rem;
  color: #f1c40f;
}
.homepage-wr-see-more:hover {
  background: linear-gradient(to right, #484848 20%, #474747);
  transition: all 0.1s ease-in-out;
  color: #f1c40f;
}
@media screen and (max-width: 800px) {
  .homepage-wr-see-more {
    width: 16rem;
    height: 2rem;
  }
}

.homepage-wr-recent-search-square .wrrss-top {
  padding: 0rem 0rem;
  font-size: 0.8rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.homepage-wr-recent-search-square .wrrss-bottom {
  padding: 0rem 0rem;
  font-size: 0.7rem;
  /* Add the necessary properties */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.homepage-button-mc {
  display: inline-block;
  padding: 1rem 1.2rem;
  background: linear-gradient(to right, #154fa7 20%, #12428a);
  width: 7.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.9rem;
  color: #dddddd;
  font-size: 1rem;
  border-radius: 0.15rem;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 2px 2px 2px 0px #2a2a2c;
  border: 1px solid rgba(79, 101, 143, 0.8901960784);
}
.homepage-button-mc:hover {
  transform: scale(1.05);
  transition: all 0.1s ease-in-out;
  color: #f1c40f;
}
.homepage-button-mc:active {
  transform: scale(0.95);
  transition: all 0.1s ease-in-out;
}/*# sourceMappingURL=HomePage.css.map */