@import "../variables/Variables.scss";

body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #48444b;
    color: #333;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .chart {
    margin-top: 2rem;
  }

  .box-plot-container {
    width: 100%;
    height: 90vh; // Change the height to a value that fits your requirements
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0rem;
    margin-top: 1rem;
    flex: 1 1 40%; /* Adjust the '30%' value to control the width of the plots */
  min-width: 300px; /* Adjust this value to set a minimum width for the plots */
}

.plots-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px; /* Adjust this value to control the space between the plots */
}

.season-name-text {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0rem;
  margin-top: 0rem;
  color: #cfcfcf;
}

.top-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 3rem;
  background-color: #2d2b2f;
  border: solid 1px #383838;
  width: auto;
  padding: 1rem 2rem;
  align-self: center;
  border-radius: .2rem;
}

.disclaimer-text-bpc {
  font-size: .6rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0rem;
  margin-top: 0rem;
  color: #a3a3a3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-text {
  font-size: .8rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0rem;
  margin-top: 0.2rem;
  color: #cfcfcf;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-text-chart-page {
  color: #cfcfcf;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.current-week-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 1rem;
  color: #cfcfcf;
  background: linear-gradient(to right, #3c3c3c 20%, #424242 );
  width: 30rem;
  align-self: center;
  height: 2.6rem;
  border: solid 1px #9f9f9f;
  border-radius: 0.2rem;
  margin-bottom: -1rem;
  position: relative;

  @media screen and (max-width: 800px) {
    width: 85%;
    margin-top: .3rem;
    margin-bottom: 0rem;
    font-size: .9rem;
  }

  &:hover {
    background: linear-gradient(to right, #154fa7 20%, #12428a);
    color: #cfcfcf;
    scale: 1.02;
    cursor: pointer;
  }
}

.notification-bubble {
  position: absolute;
  top: -10px; /* Adjust these values to position the bubble correctly */
  right: -10px; /* Adjust these values to position the bubble correctly */
  background-color: $brandColor; /* Change the color as needed */
  color: white;
  border-radius: 40%;
  padding: .1rem .3rem; /* Adjust the padding to make the bubble circular */
  font-size: 12px; /* Adjust the font size */
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quali-race-active {
  margin-left: .4rem;
        margin-right: .4rem;
        padding: .0rem .4rem .0rem .4rem;
        color: #cfcfcf;
        background: linear-gradient(to right, #434343 20%, #3d3d3d);
        border-radius: .3rem;
        border: none;
        
        &:hover {
            cursor: pointer;
            color:#f1c40f;
            transform: scale(1.01);
              transition: all 0.2s ease-in-out;
        }
}

.quali-race-inactive {
  margin-left: .4rem;
        margin-right: .4rem;
        padding: .0rem .3rem .0rem .3rem;
        color: #cfcfcf;
        background-color: #29272b;
        border-radius: .3rem;
        border: none;
        
        &:hover {
            cursor: pointer;
            color:#f1c40f;
            transform: scale(1.01);
              transition: all 0.2s ease-in-out;
        }
}

.race-quali-button {
  background: linear-gradient(to right, #3c3c3c 20%, #424242 );
  width: 20%;
  padding-top: 0px;
  padding-bottom: 0px;
  align-self: center;
  border: solid 1px #9f9f9f;
  border-radius: 0.2rem;
  color: #cfcfcf;
  margin-top: 1rem;

  @media screen and (max-width: 800px) {
    width: 50%;
    margin-top: .3rem;
    
  }

  &:hover {
    background: linear-gradient(to right, #575757 20%, #717171 );
    color: #cfcfcf;
    scale: 1.02;
  }
}

.spinner {
  margin-top: 10rem;
  margin-right: 2rem;
  display: inline-block;
  width: 80px;
  height: 80px;
}
.spinner:after {
  content: " ";
  display: block;
  width: 88px;
  height: 88px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid $brandColor;
  border-color: $brandColor transparent $brandColor transparent;
  animation: dual-ring 1.2s linear infinite;
}

@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text-div-bpc {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 10rem;
  font-size: large;
  color: #dddddd;
}

.top-background-div-bpc {
  // background: linear-gradient(to right, #2c3e50 20%, #223446);
background: linear-gradient(-45deg, #5e3169, #2f3585, #1d427a, #5e3169);
background-size: 400% 400%;
animation: gradient 15s ease infinite;
height: 14rem;
width: 48rem;
display: flex;
justify-content: center;
align-items: center;
align-self: center;
border-radius: 0.6rem;
color: #dddddd;
margin-top: 5rem;

h1 {
  font-size: x-large;
}

  @media screen and (max-width: 800px) {
    border-radius: 0px;
    width: 100%;
    height: 14rem;
    margin-top: 0rem;

    h1 {
      font-size: 1.4rem;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

// .last-season-text-bpc {
//   margin-top: 14rem;
//   margin-bottom: 2rem;
//   font-size: 3rem;
//   background: -webkit-linear-gradient(-45deg, #204988, #5e3169);
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
// }
