@import "../variables/Variables.scss";


.wr-page-main {
    color: #cfcfcf;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .wr-selection-square-container-div {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .wr-selection-square-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background-color: #605f5f4b;
        border-radius: .5rem;
        margin: .1rem;
        padding: 0.2rem 0.3rem;
        font-size: .7rem;

        &:hover {
            cursor: pointer;
            background-color: #545454;
          }

          @media screen and (max-width: 800px) {
            padding: 0.2rem 0.5rem;
            font-size: .7rem;
            
          }
    }

    .wr-car-selection-big-containing-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 1rem;
        font-size: 1.0rem;
        background-color: #80808026;
        border-top-right-radius: .5rem;
        border-top-left-radius: .5rem;
        border-bottom: #cfcfcf16 1px solid;
        margin: 0rem 5rem;
        width: 40rem;
        height: 10rem;

          @media screen and (max-width: 800px) {
            width: 100%;
            
          }
    }
    .wr-track-selection-big-containing-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 1rem;
        font-size: 1.0rem;
        background-color: #80808026;
        border-bottom-right-radius: .5rem;
        border-bottom-left-radius: .5rem;
        margin: 0rem 5rem;
        width: 40rem;
        height: 10rem;

          @media screen and (max-width: 800px) {
            width: 100%;
            
          }
    }

    .wr-search-bar {
        background-color: #80808000;
        border-bottom: 1px solid #cfcfcf;
        width: 20rem;
        border-right: none;
        overflow: hidden;
        color: #cfcfcf;
        margin-top: .5rem;
        margin-bottom: 1rem;
        border-top: none;
        border-left: none;
    }

    .wr-selection-go-button {
        background: linear-gradient(to right, #154fa7 20%, #12428a);
        border-radius: .5rem;
        padding: .5rem 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: #cfcfcf;
        width: 10rem;

        &:hover {
            cursor: pointer;
            background-color: #545454;
          }
    }

}

