@import "../variables/Variables.scss";

.ss_main_div{
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    --s: 1550px; /* control the size */
      background:
        linear-gradient(135deg,#0000 18.75%,#252427 0 31.25%,#0000 0),
        repeating-linear-gradient(45deg,#252427 -6.25% 6.25%,#262528 0 18.75%);
      background-size: var(--s) var(--s); 

}
.safety-time-title{
    font-size: 1.5rem;
    font-weight: bold;
    color: #dddddd;
    background-color: #353336;
    border: #a5a5a561 1px solid;
    margin-top: 2rem;
    margin-bottom: 0rem;
    border-radius: .2rem;
    padding: .2rem 1rem;
}

.toggle-button-safety{
    margin-top: 0rem;
    margin-bottom: 1rem;
    width: 60%;
    border-radius: .1rem;
    background-color: #423f44;
    border: solid 1px #727073;
    color: #dddddd;
}
.min-ss-ent {
    width: 4rem;
    padding-left: 14px;
    font-size: .8rem;
    background-color: #29272b;
    font-weight: light;
    border: none;
    color: $textYellow;
    border-bottom: #dddddd 1px solid;
    margin-left: 6px;
}

.ss_title_min {
    font-size: .8rem;
    font-weight: light;
    color: #dddddd;
    
    @media screen and (min-width: 600px) {
    }
}

.ss_entr-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1.6rem;
}

.ss-disclaimer-text-div {
    margin-top: 2rem;
    font-size: small;
    color: #a5a5a5;
    @media screen and (max-width: 800px) {
        font-size: x-small;
    }
        
    
}