$brandColor: #154fa7;
$brandColorHover: #24487c;
$textYellow: #f1c40f;
$textYellowLight: rgb(230, 194, 54);
$backgroundGrurple: #29272b;
$accentPink: #CC8B86;
$chartBlue: #1e53a3dc;
$chartBlueLight: #3c66a7;
$chartBlueDark: #1e3da3dc;
$textWhite: #dddddd;

$test: #272629;
$test2: #25242700;

$newBackground: #252427;

$squareSelectionBackground: linear-gradient(90deg, #2f2f2f 20%, #2e2e2e);