body, .world-records-result-page-cont {
  overflow-x: hidden;
}

.world-records-result-page-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.world-records-result-page-cont .wrrp-title-div {
  color: #cfcfcf;
}
.world-records-result-page-cont .wrrp-title-div h2 {
  color: #cfcfcf;
  margin-bottom: 0rem;
  font-size: 1.6rem;
  margin-top: 0.1rem;
}
.world-records-result-page-cont .wrrp-title-div pre {
  color: #cfcfcf;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-title-div h2 {
    font-size: 1.5rem;
    margin-bottom: -0.3rem;
  }
}
.world-records-result-page-cont .wrrp-table-cont-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  font-size: 1rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  margin: 0rem 5rem;
  width: 40rem;
}
.world-records-result-page-cont .wrrp-table-top-bar-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(118, 82, 82, 0.4392156863);
  width: 40rem;
  height: 2rem;
  color: #cfcfcf;
}
.world-records-result-page-cont .wrrp-table-top3-cont {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 38rem;
  position: relative;
}
.world-records-result-page-cont .wrrp-table-top3-cont h1 {
  color: #cfcfcf;
  font-size: 1.6rem;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top3-cont {
    width: 100%;
    margin-left: -3rem;
  }
}
.world-records-result-page-cont .wrrp-table-top3-pos-cont {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-wrap: nowrap;
  color: #cfcfcf;
}
.world-records-result-page-cont .wrrp-table-top3-1 {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  background: linear-gradient(to right, #0e2f61 20%, #0f346b);
  width: 33rem;
  height: 3rem;
}
.world-records-result-page-cont .wrrp-table-top3-1 h1 {
  color: #cfcfcf;
  font-size: 1.2rem;
  margin: 0rem 1rem;
}
.world-records-result-page-cont .wrrp-table-top3-1 h1:hover {
  cursor: pointer;
  text-decoration: underline;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top3-1 h1 {
    font-size: 0.9rem;
  }
}
.world-records-result-page-cont .wrrp-table-top3-1 h2 {
  color: #ebc000;
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0rem 1rem;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top3-1 h2 {
    margin-top: 0.2rem;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top3-1 {
    width: 30rem;
    z-index: 1;
    flex-direction: column;
  }
}
.world-records-result-page-cont .wrrp-table-top3-2 {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  background: linear-gradient(to right, rgba(14, 47, 97, 0.6784313725) 20%, rgba(15, 52, 107, 0.7019607843));
  width: 30rem;
  height: 3rem;
}
.world-records-result-page-cont .wrrp-table-top3-2 h1 {
  color: #cfcfcf;
  font-size: 1.2rem;
  margin: 0rem 1rem;
}
.world-records-result-page-cont .wrrp-table-top3-2 h1:hover {
  cursor: pointer;
  text-decoration: underline;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top3-2 h1 {
    font-size: 0.9rem;
  }
}
.world-records-result-page-cont .wrrp-table-top3-2 h2 {
  color: #c2c2c2;
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0rem 1rem;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top3-2 h2 {
    margin-top: 0.2rem;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top3-2 {
    width: 30rem;
    z-index: 1;
    flex-direction: column;
  }
}
.world-records-result-page-cont .wrrp-table-top3-3 {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  background: linear-gradient(to right, rgba(14, 47, 97, 0.3921568627) 20%, rgba(15, 52, 107, 0.4));
  width: 27rem;
  height: 3rem;
}
.world-records-result-page-cont .wrrp-table-top3-3 h1 {
  color: #cfcfcf;
  font-size: 1.2rem;
  margin: 0rem 1rem;
}
.world-records-result-page-cont .wrrp-table-top3-3 h1:hover {
  cursor: pointer;
  text-decoration: underline;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top3-3 h1 {
    font-size: 0.9rem;
  }
}
.world-records-result-page-cont .wrrp-table-top3-3 h2 {
  color: rgb(179, 136, 98);
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0rem 1rem;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top3-3 h2 {
    margin-top: 0.2rem;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top3-3 {
    width: 30rem;
    z-index: 1;
    flex-direction: column;
  }
}
.world-records-result-page-cont .wrrp-table-field-row-cont {
  display: flex;
  flex-direction: column;
  justify-content: left;
  background: linear-gradient(to right, #383838 20%, #404040);
  color: #cfcfcf;
  width: 38rem;
  margin-top: 0rem;
  padding: 0px;
  border-radius: 0.3rem;
  overflow: hidden;
  margin-bottom: 1rem;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-field-row-cont {
    width: 100%;
  }
}
.world-records-result-page-cont .wrrptpos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-right: #828282 2px solid;
  background: linear-gradient(to right, #2b2b2b 20%, #343434);
  width: 3rem;
}
.world-records-result-page-cont .wrrp-table-row-f {
  display: flex;
  flex-direction: row;
  justify-content: left;
  border-bottom: rgba(130, 130, 130, 0.6705882353) 1px solid;
  background: linear-gradient(to right, #2b2b2b 20%, #343434);
  padding: none;
}
.world-records-result-page-cont .wrrp-table-row-f .wrrptrd {
  color: #cfcfcf;
  font-size: 1rem;
  margin: 0rem 1rem 0rem 1.5rem;
  line-height: 2rem;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-row-f .wrrptrd {
    font-size: 0.8rem;
  }
}
.world-records-result-page-cont .wrrp-table-row-f .wrrptl {
  display: flex;
  flex-direction: row;
  justify-content: left;
  background: linear-gradient(to right, #2b2b2b 20%, #343434);
  width: 26rem;
  height: 2rem;
  border-right: rgba(207, 207, 207, 0.2039215686) 1px solid;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-row-f .wrrptl {
    height: auto;
    align-items: center;
    height: auto;
  }
}
.world-records-result-page-cont .wrrp-table-row-f .wrrptl h1 {
  color: #cfcfcf;
  font-size: 1.1rem;
  margin: 0rem 1rem;
  padding-right: 1rem;
  line-height: 2rem;
  border-right: rgba(207, 207, 207, 0.2039215686) 1px solid;
}
.world-records-result-page-cont .wrrp-table-row-f .wrrptl h2 {
  color: #cfcfcf;
  font-size: 1rem;
  margin: 0rem 1rem;
  line-height: 2rem;
}
.world-records-result-page-cont .wrrp-table-row-f .wrrptl h2:hover {
  font-size: 1.03rem;
  cursor: pointer;
  text-decoration: underline;
}
.world-records-result-page-cont .wrrp-table-top-3-flex {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 38rem;
}
.world-records-result-page-cont .wrrp-table-top-3-flex .wrrp-table-top3-pos1 {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  background: linear-gradient(to right, #2b2b2b 20%, #343434);
  width: auto;
  padding: 1rem 1rem;
  height: 3rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cfcfcf;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top-3-flex .wrrp-table-top3-pos1 {
    display: none;
  }
}
.world-records-result-page-cont .wrrp-table-top-3-flex .wrrp-table-top3-pos2 {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  background: linear-gradient(to right, #2b2b2b 20%, #343434);
  width: auto;
  height: 3rem;
  padding: 1rem 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cfcfcf;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top-3-flex .wrrp-table-top3-pos2 {
    display: none;
  }
}
.world-records-result-page-cont .wrrp-table-top-3-flex .wrrp-table-top3-pos3 {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  background: linear-gradient(to right, #2b2b2b 20%, #343434);
  width: auto;
  padding: 1rem 1rem;
  height: 3rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cfcfcf;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top-3-flex .wrrp-table-top3-pos3 {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top-3-flex {
    width: auto;
    overflow: hidden;
  }
}
.world-records-result-page-cont .wrrp-table-top3-right-cont {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 8rem;
  text-wrap: nowrap;
  color: #cfcfcf;
}
.world-records-result-page-cont .wrrp-table-top3-right-cont .wrrp-table-top3-right-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  height: 3rem;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top3-right-cont .wrrp-table-top3-right-1 {
    margin-left: 0rem;
  }
}
.world-records-result-page-cont .wrrp-table-top3-right-cont .wrrp-table-top3-right-2 {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: -2rem;
  height: 3rem;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top3-right-cont .wrrp-table-top3-right-2 {
    margin-left: 0rem;
  }
}
.world-records-result-page-cont .wrrp-table-top3-right-cont .wrrp-table-top3-right-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -9rem;
  height: 3rem;
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top3-right-cont .wrrp-table-top3-right-3 {
    margin-left: 0rem;
  }
}
@media screen and (max-width: 800px) {
  .world-records-result-page-cont .wrrp-table-top3-right-cont {
    width: auto;
    font-size: 0.7rem;
    margin-left: -5rem;
    z-index: 2;
  }
}

.no-wr-found-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}
.no-wr-found-cont .no-wr-found-div {
  color: #cfcfcf;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  border-radius: 1rem;
  background-color: rgba(128, 128, 128, 0.2941176471);
  padding: 1rem 1rem;
}
.no-wr-found-cont .go-to-search-wr-button1 {
  color: #cfcfcf;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  background: linear-gradient(to right, #0e2f61 20%, #0f346b);
  width: 20rem;
  height: 3rem;
  border: 2px solid rgba(207, 207, 207, 0.0941176471);
  border-radius: 1rem;
}
.no-wr-found-cont .go-to-search-wr-button1:hover {
  background: linear-gradient(to right, #10366f 20%, #113976);
  cursor: pointer;
}/*# sourceMappingURL=WorldRecordsResultPage.css.map */