@import "../variables/Variables.scss";

.register-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: $backgroundGrurple;
}

h2 {
    margin-bottom: 20px;
    color: #cfcfcf;
}

.register-form {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border-radius: 5px;
    background: linear-gradient(-45deg, #733583, #154fa7, #154fa7, #733583);
    background-size: 400% 400%;
    animation: gradient 30s ease infinite;
}

.register-form input,
.register-form select {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.register-form button {
    padding: 10px;
    background-color: #f1c40f;
    color: #2c2c2c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.register-form button:hover {
    background-color: #0056b3;
}

.register-form select {
    appearance: none;
    background-color: #fff;
    cursor: pointer;
}
